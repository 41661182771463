import { Tag } from "antd";
import { PRICE_POLICY_TYPE } from "config/constants";
import { useGetProdAttribute } from "hooks/attribute";
import { useGetManufacturers } from "hooks/manufacturer/manufacturer";
import { useGetRegionsList } from "hooks/regions";
import { useWarehouse } from "hooks/warehouse";
import { find, hasIn, reject } from "lodash";
import { SELLING_STATUS_OPTIONS, VAT_TYPE } from "pages/App/Product/constants";
import { useTranslation } from "react-i18next";
import "./index.scss";

const TagCustom = ({ label, filterText, handleOnClose, isDoubleTag = false, children, styleTag }) => {
  return (
    <Tag closable onClose={handleOnClose} style={{ ...styleTag }}>
      {isDoubleTag ? (
        children
      ) : (
        <>
          <span className="label">{label}:</span>
          <span>&nbsp;{filterText}</span>
        </>
      )}
    </Tag>
  );
};

const TagsList = ({ params, setParams, refetch }) => {
  const listFilter = params.filters;
  const { t } = useTranslation();
  const { dataManufactures } = useGetManufacturers();
  const { warehouses } = useWarehouse();
  const { regions } = useGetRegionsList();
  const { data: prodAtt } = useGetProdAttribute(params.filters.categoryIDs);

  const pricesLevelList = [
    {
      label: t("product.priceLevel1"),
      key: "priceLevel1",
    },
    {
      label: t("product.priceLevel2"),
      key: "priceLevel2",
    },
    {
      label: t("product.priceLevel3"),
      key: "priceLevel3",
    },
    {
      label: t("product.priceLevel4"),
      key: "priceLevel4",
    },
  ];

  const renderTagNameManufactures = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const manufacturersList = listFilter[`${key}`]
        .map((item) => find(dataManufactures, { value: item }))
        .map((item) => item?.label)
        .join(", ");
      return (
        manufacturersList && (
          <TagCustom
            label={t("product.brand")}
            filterText={manufacturersList}
            handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
          />
        )
      );
    } else {
      return null;
    }
  };

  const renderTagPriceLevel = (label, key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`] !== undefined) {
      const renderFilterText = (objectKey) => {
        switch (objectKey?.isSpecified) {
          case true: {
            if (objectKey.range.from && !objectKey.range.to) {
              return `>=${objectKey.range.from}`;
            }
            if (!objectKey.range.from && objectKey.range.to) {
              return `<=${objectKey.range.to}`;
            }

            if (objectKey.range.to && objectKey.range.from) {
              return `${objectKey.range.from} - ${objectKey.range.to}`;
            } else {
              return t("product.havePrice");
            }
          }
          case false: {
            return t("product.haveNotPrice");
          }
          default: {
            return t("common.all");
          }
        }
      };
      return listFilter[`${key}`] === undefined ? (
        <></>
      ) : (
        <TagCustom
          key={label}
          label={label}
          filterText={renderFilterText(listFilter[`${key}`])}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    }
  };

  const renderTagRealStocks = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const listWarehouse = listFilter[`${key}`].map((item) => find(warehouses, { id: item.warehouseID }));
      const renderRealStocks = (from, to) => {
        if (!from && to) {
          return `<=${to}`;
        }
        if (from && !to) {
          return `>=${from}`;
        }

        if (from && to) {
          return `${from} - ${to}`;
        }
        return "";
      };

      return listFilter[`${key}`].map((item, index) => (
        <TagCustom
          key={item.warehouseID}
          label={`${t("product.realStock")} (${listWarehouse[index]?.code})`}
          filterText={renderRealStocks(item?.range?.from, item?.range?.to)}
          handleOnClose={() => handleOnCloseTagWarehouses(item.warehouseID, key)}
        />
      ));
    } else {
      return null;
    }
  };

  const renderSupplierStocksTag = (key) => {
    if (!hasIn(listFilter, key) || !listFilter[key]) return null;

    const listRegion = listFilter[key].map((item) => {
      return find(regions, { id: item?.regionID });
    });

    const renderSupplierStocks = (from, to) => {
      if (!from && to) return `<=${to}`;
      if (from && !to) return `>=${from}`;
      if (from && to) return `${from} - ${to}`;
      return "";
    };

    const renderStockInfo = (label, code, range) => (
      <span>
        <span style={{ color: "#5b6673" }}>
          {label} ({code || "--"})
        </span>
        : {renderSupplierStocks(range?.from, range?.to) || 0}
      </span>
    );

    const renderChildren = (item, index) => {
      const regionCode = listRegion[index]?.name;
      const realStockText = item.rangeRealStock.from
        ? renderStockInfo("Tồn khả dụng", regionCode, item.rangeRealStock)
        : null;
      const realStockVATText = item.rangeRealStockVAT.from
        ? renderStockInfo("Tồn VAT", regionCode, item.rangeRealStockVAT)
        : null;

      return (
        <div style={{ padding: "0 4px" }}>
          {realStockText}
          {realStockText && realStockVATText && <br />}
          {realStockVATText}
        </div>
      );
    };

    return listFilter[key].map((item, index) => {
      return (
        (item?.rangeRealStock || item?.rangeRealStockVAT) && (
          <TagCustom
            styleTag={{ display: "inline-flex", height: "max-content" }}
            key={item.regionID}
            isDoubleTag={true}
            children={renderChildren(item, index)}
            handleOnClose={() => handleOnCloseTagRegions(item.regionID, key)}
          />
        )
      );
    });
  };

  const renderTagVat = (key) => {
    const renderVatText = (value) => {
      if (value === true) {
        return t("common.yes");
      } else if (value === false) {
        return t("common.no");
      } else if (value === null) {
        return t("common.all");
      } else {
        return "";
      }
    };

    if (hasIn(listFilter, key) && listFilter[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={t("product.exportVat")}
          filterText={renderVatText(listFilter[`${key}`])}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagPricePolicy = (key) => {
    const renderVatText = (value) => {
      if (value === PRICE_POLICY_TYPE.QUANTITY_DISCOUNT) {
        return t("product.pricePolicy.yes");
      } else if (value === PRICE_POLICY_TYPE.NO_QUANTITY_DISCOUNT) {
        return t("product.pricePolicy.no");
      } else if (value === null) {
        return t("common.all");
      } else {
        return "";
      }
    };

    if (hasIn(listFilter, key) && listFilter[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={t("product.tableTitle.pricePolicy")}
          filterText={renderVatText(listFilter[`${key}`])}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagVatPercent = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const renderVatPercent = (from, to) => {
        if (typeof from !== "number" && to) return `<= ${to}`;
        if (from && typeof to !== "number") return `>= ${from}`;
        if (from === to && typeof from === "number" && typeof to === "number") return from;
        if (typeof from === "number" && typeof to === "number") return `${from} - ${to}`;
        return t("common.other");
      };

      return (
        <TagCustom
          label={`${t("product.vatPercent")}`}
          filterText={renderVatPercent(listFilter[`${key}`]?.from, listFilter[`${key}`]?.to)}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagRealStockHasVat = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const renderVatPercent = (from, to) => {
        if (typeof from !== "number" && to) return `<= ${to}`;
        if (from && typeof to !== "number") return `>= ${from}`;
        if (from === to && typeof from === "number" && typeof to === "number") return from;
        if (typeof from === "number" && typeof to === "number") return `${from} - ${to}`;
        return t("common.other");
      };

      return (
        <TagCustom
          label={`${t("product.tableTitle.inStockVat")}`}
          filterText={renderVatPercent(listFilter[`${key}`]?.from, listFilter[`${key}`]?.to)}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagAttributeValues = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const listAttribute = listFilter[`${key}`].map((item) => find(prodAtt, { id: item.attributeID }));

      const renderAttributeText = (valueFilter, valueIndex) => {
        return valueFilter.valueIDs
          .map((valueID) => listAttribute[valueIndex]?.values?.find((value) => value.id === valueID).value)
          .join(", ");
      };

      return listFilter[`${key}`].map((item, index) => (
        <TagCustom
          key={item.attributeID}
          label={`${listAttribute[index]?.name}`}
          filterText={renderAttributeText(item, index)}
          handleOnClose={() => handleOnCloseTagAttributes(item.attributeID, key)}
        />
      ));
    } else {
      return null;
    }
  };

  const renderTagMOQ = (key) => {
    if (
      hasIn(listFilter, key) &&
      listFilter[`${key}`] &&
      listFilter[`${key}`].from !== null &&
      listFilter[`${key}`].to !== null
    ) {
      const renderMOQ = (from, to) => {
        if (typeof from !== "number" && to) return `<= ${to}`;
        if (from && typeof to !== "number") return `>= ${from}`;
        if (from === to && typeof from === "number" && typeof to === "number") return from;
        if (typeof from === "number" && typeof to === "number") return `${from} - ${to}`;
        return "";
      };

      return (
        <TagCustom
          label={`Mức MOQ`}
          filterText={renderMOQ(listFilter[`${key}`]?.from, listFilter[`${key}`]?.to)}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const handleOnCloseTagPriceManufacturesVat = (key) => {
    const cloneParams = { ...params };
    delete cloneParams.filters[`${key}`];
    setParams({
      ...cloneParams,
    });
    refetch();
  };

  const handleOnCloseTagWarehouses = (idWarehouses, key) => {
    const cloneParams = { ...params };
    //array real stock after delete item tag
    const arrayRealStocks = reject(cloneParams.filters[`${key}`], (item) => item.warehouseID === idWarehouses);
    //new params after delete item tag
    const newParams = {
      ...cloneParams,
      filters: { ...cloneParams.filters, [`${key}`]: [...arrayRealStocks] },
      pagination: {
        ...cloneParams.pagination,
        offset: 0,
      },
    };
    setParams({ ...newParams });
    refetch();
  };

  //
  const handleOnCloseTagRegions = (idRegions, key) => {
    const cloneParams = { ...params };
    //array stock supplier after delete item tag
    const arrayStocksSupplier = reject(cloneParams.filters[`${key}`], (item) => item.regionID === idRegions);

    //new params after delete item tag
    const newParams = {
      ...cloneParams,
      filters: { ...cloneParams.filters, [`${key}`]: [...arrayStocksSupplier] },
      pagination: {
        ...cloneParams.pagination,
        offset: 0,
      },
    };
    setParams({ ...newParams });
  };

  const handleOnCloseTagAttributes = (idAtt, key) => {
    const cloneParams = { ...params };
    const arrayAttributes = reject(cloneParams.filters[`${key}`], (item) => item.attributeID === idAtt);
    const newParams = {
      ...cloneParams,
      filters: {
        ...cloneParams.filters,
        [`${key}`]: arrayAttributes.length > 0 ? [...arrayAttributes] : undefined,
      },
      pagination: {
        ...cloneParams.pagination,
        offset: 0,
      },
    };
    setParams({ ...newParams });
    refetch();
  };

  const renderTagSellingStatus = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const sellingStatusList = listFilter[`${key}`]
        .map((item) => find(SELLING_STATUS_OPTIONS, { value: item }))
        .map((item) => item?.label)
        .join(", ");
      return (
        sellingStatusList && (
          <TagCustom
            label={"Trạng thái"}
            filterText={sellingStatusList}
            handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
          />
        )
      );
    } else {
      return null;
    }
  };

  const renderTagSellingVatType = (key) => {
    const vatTypeOptions = [
      {
        label: t("v2.product.drawer_edit_product.export_vat"),
        value: VAT_TYPE.REQUIRED,
      },
      { label: t("v2.product.drawer_edit_product.not_export_vat"), value: VAT_TYPE.NOT_REQUIRED },
      { label: t("v2.product.drawer_edit_product.optional"), value: VAT_TYPE.OPTIONAL },
    ];
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const vatList = listFilter[`${key}`]
        .map((item) => find(vatTypeOptions, { value: item }))
        .map((item) => item?.label)
        .join(", ");
      return (
        vatList && (
          <TagCustom
            label={"Vat?"}
            filterText={vatList}
            handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
          />
        )
      );
    } else {
      return null;
    }
  };

  const renderTagVatValue = (key) => {
    if (hasIn(listFilter, key) && listFilter[`${key}`]) {
      const renderVatPercent = (from, to) => {
        if (typeof from !== "number" && to) return `<= ${to}`;
        if (from && typeof to !== "number") return `>= ${from}`;
        if (from === to && typeof from === "number" && typeof to === "number") return from;
        if (typeof from === "number" && typeof to === "number") return `${from} - ${to}`;
        return t("common.other");
      };

      return (
        <TagCustom
          label={`${t("product.tableTitle.vatValue")}`}
          filterText={renderVatPercent(listFilter[`${key}`]?.from, listFilter[`${key}`]?.to)}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="tags-list">
      {/* Tag NSX */}
      {renderTagNameManufactures("manufacturerIDs")}
      {/* Tag giá cấp */}
      {pricesLevelList.map(({ label, key }) => renderTagPriceLevel(label, key))}
      {/* Tồn kho */}
      {renderTagRealStocks("realStocks")}
      {/* vat */}
      {renderTagVat("vat")}
      {/* price policy */}
      {renderTagPricePolicy("pricePolicyType")}
      {/* % vat */}
      {renderTagVatPercent("vatPercent")}

      {renderTagRealStockHasVat("realStockHasVat")}
      {/* Thuộc tính*/}
      {renderTagAttributeValues("attributeValues")}
      {/* Selling status */}
      {renderTagSellingStatus("sellingStatuses")}
      {/* Tồn khả dụng/ tồn vat của ncc */}
      {renderSupplierStocksTag("supplierStocks")}

      {renderTagSellingVatType("vatTypes")}

      {renderTagVatValue("vatValue")}
      {renderTagMOQ("minOrderQuantity")}
    </div>
  );
};

export default TagsList;
